export default {
  BOOKING_LIST: "Reservations",
  PENDING_BOOKING_LIST: "List of pending reservations",
  ADD_BOOKING: "Add reservation",
  BOOKING_ADDED: "Reservation confirmed",
  EDIT_BOOKING: "Modify reservation",
  BOOKING_UPDATED: "Reservation updated",
  DELETE_THIS_BOOKING: "Delete this reservation?",
  BOOKING_DELETED: "Reservation deleted",
  ADULTS_CAPACITY: "Adultes",
  PETS_CAPACITY: "Pets",
  CHILDREN_CAPACITY: "Children",
  BOOK_SPOT: "Book a spot",
  STARTING_FROM: "Starting from",
  PRICE_PER_NIGHT: "per night",
  AVAILABILITY: "Availability",
  AVAILABLE: "Available",
  NOT_AVAILABLE: "Not available",
  PARTIALLY_AVAILABLE: "Partially available",
  FILTER_BY_SPOT_CATEGORY: "Pitch or site",
  FILTER_BY_SPOT_TYPE: "Service",
  FILTER_BY_SPOT_LENGTH: "Equipment dimensions",
  FILTER_BY_EQUIPMENT: "Equipment",
  UNIT_FEET: "feet",
  SPOTS_LIST_FOUND: "{total} sites found",
  BOOKING_CODE: "Confirmation code",
  DURATION: "Number of nights",
  PRICE_PER_DAY: "Price/day",
  ARRIVING_AT: "Arrival",
  LEAVING_AT: "Leaving",
  EXISTING_CUSTOMER: "Client existant",
  CREATE_CUSTOMER: "Nouveau client",
  SECURE_BOOKING: "Secure",
  ADD_TO_CART: "Add",
  SPECIAL_REQUEST: "Customer special request",
  INTERNAL_NOTE: "Internal note",
  SELECT_CONFIRMATION_EXPIRATION_DATE:
    "Select an expiration date for confirmation",
  SEND_TO_CUSTOMER_EMAIL: "Send to customer's email address",
  CONFIRM_AND_SEND: "Confirm and send",
  BLOCK_THIS_SPOT: "Block this spot",
  VIEW_BOOKING: "View booking",
  PENDING: "Pending",
  BOOKINGS_LIST: "Bookings list",
  BOOKING_DETAILS: "Booking details",
  BOOKING_NUMBER: "Booking Number",
  CHECK_IN: "Check in",
  CHECK_OUT: "Check out",
  BOOKING_DAYS: "Reserved days",
  BOOKING_SPOT: "Booking spot",
  BOOKING_INFORMATION: "Booking information",
  OCCUPATION: "Occupation",
  SPOT_INFORMATION: "Spot information",
  SELECTED_PERIOD: "Selected period",
  SERVICE: "Service",
  CONFIRM_THIS_BOOKING:
    "Do you really want to confirm and send this reservation to <span class='text-muted'>{email}</span>?",
  CONFIRM_BOOKING: "Confirmation",
  BOOKING_CONFIRMED: "Reservation confirmed",
  CONFIRMED: "No warranty",
  TOTAL_BOOKINGS: "{total} reservations",
  CONFORM_PAYMENT: "Confirm payment",
  BOOKING_IDENTIFIER: "# Booking {code}",
  BOOKING_DATE: "Booking date",
  MY_CURRENT_BOOKINGS: "My bookings list",
  OTHERS_CURRENT_BOOKINGS: "Others bookings",
  CONFIRM_THE_BOOKING: "Confirm the booking",
  BOOK_WITH_PAYMENT: "Book with payment",
  BOOK_WITHOUT_PAYMENT: "Book without payment",
  TAKE_CONTROL_OF_BOOKING:
    "<p>Another user ({email}) is working on this booking</p><br> <b>Do you still want to take control?</b>",
  BEDS_QTY: "Number of beds",
  ROOMS_QTY: "Number of rooms",
  SELECTED_EQUIPMENT: "Selected equipment",
  REASON_UNAVAILABLE: "Unavailable",
};
